import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import React from "react";
import SkaiciuoklesPuslapisComponent from "./components/SkaiciuoklesPuslapisComponent";

let theme = createTheme({
  typography: {
    h1: {
      fontSize: '2.em'
    }
  },
  palette: {
    background: {
      default: "#e8eaf6"
    },
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SkaiciuoklesPuslapisComponent></SkaiciuoklesPuslapisComponent>
    </ThemeProvider>
  );
}

export default App;
