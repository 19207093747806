import { Box } from '@mui/material';
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import ReactGA from "react-ga";
import ChangedFields from "../ChangedFields";
import {
  calculateDarbdavioTarifas,
  calculateDarbuotojoTarifas,
  calculateGpmTarifas, emptyState
} from "../skaiciuokle/Skaiciuokle";
import FooterComponent from "./layout/FooterComponent";
import MokesciuAprasymasComponent from './MokesciuAprasymasComponent';
import RezultatuLenteleComponent from './RezultatuLenteleComponent';
import SkaiciuokleComponent from './SkaiciuokleComponent';
import SkaiciuoklesAprasymasComponent from './SkaiciuoklesAprasymasComponent';

ReactGA.initialize("UA-141218159-2"); //atlyginimo-skaiciuokle.lt
//ReactGA.initialize("UA-141218159-3"); //atlyginimoskaičiuoklė.lt
ReactGA.pageview(window.location.pathname + window.location.search);

function SkaiciuoklesPuslapisComponent() {
  const [duomenys, setDuomenys] = useState({
    ...emptyState(),
    changedField: ChangedFields.GROSS,
    taikomasNpd: "standartinis",
    pensijai: "nekaupia",
    draudejoGrupe: "I",
    draudejoTipas: "privatus",
    sutartiesTipas: "neterminuota",
    metai: "2022",
    darbdavioTarifas: calculateDarbdavioTarifas(
      "2022",
      "neterminuota",
      "privatus",
      "I"
    ),
    darbuotojoTarifas: calculateDarbuotojoTarifas("2022", "nekaupia"),
    gpmTarifas: calculateGpmTarifas("2022"),
  });

  const handleDuomenys = (duomenys: any) => {
    setDuomenys(duomenys)
  }

  return (
    <div itemScope itemType="https://schema.org/WebApplication">
      <link
        itemProp="applicationCategory"
        href="https://schema.org/FinanceApplication"
      />
      <AppBar position="static">
        <Toolbar>
          <Hidden smDown>
            <Grid container >
              <Grid item lg={7} md={10}>
                <Typography variant="h1" itemProp="name">
                  Atlyginimo skaičiuoklė {duomenys.metai} metams
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Typography variant="h1" itemProp="name">
              Atlyginimo skaičiuoklė {duomenys.metai} metams
            </Typography>
          </Hidden>
        </Toolbar>
      </AppBar>

      <Box m={{ xs: 2, md: 5 }}>
        <Grid container spacing={{ xs: 2, md: 4 }} sx={{ flexGrow: 1 }} justifyContent="center">
          <Grid item xs={12} md={10} lg={7}>
            <Paper sx={{ p: 4 }} elevation={3}>
              <SkaiciuokleComponent duomenys={duomenys} onDuomenys={handleDuomenys} />
            </Paper>
          </Grid>

          <Grid item md={10} lg={7}>
            <Paper elevation={3}>
              <RezultatuLenteleComponent duomenys={duomenys} />
            </Paper>
          </Grid>

          <Grid item lg={7} md={10}>
            <Paper sx={{ p: 4 }} elevation={3}>
              <SkaiciuoklesAprasymasComponent />
            </Paper>
          </Grid>

          <Grid item lg={7} md={10}>
            <Paper sx={{ p: 4 }} elevation={3}>
              <MokesciuAprasymasComponent />
            </Paper>
          </Grid>

        </Grid>
      </Box>
      <FooterComponent></FooterComponent>
    </div >
  );
}

export default SkaiciuoklesPuslapisComponent;
