import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";

const SkaiciuoklesAprasymasComponent = () => {
    return (
        <>
            <Typography variant="h5" component="h2">
                Atlyginimo skaičiuoklė
            </Typography>
            <br />
            <Typography variant="body1">
                Atlyginimo skaičiuoklė - tai internetinė programa, skirta algos
                sumai po mokesčių (į rankas / neto) arba sumai prieš mokesčius (ant
                popieriaus / bruto) apskaičiuoti. Įvedus sumą ant popieriaus,
                apskaičiuojama suma į rankas. Įvedus sumą į rankas, apskaičiuojama
                suma ant popieriaus. Skaičiuoklė taip pat suskaičiuoja darbo vietos
                kainą (darbdavio išlaidas), esant nurodytoms darbo užmokesčio sumoms
                bei parodo, kokia bus atlyginimo suma prieš ir kokia po mokesčių.
            </Typography>
            <br />
            <Typography variant="body1">
                Atlyginimo skaičiuoklė apskaičiuoja pajamų mokesčio ir socialinio
                draudimo įmokų sumas. Skaičiuoklėje pateikiamos nuorodos į
                Valstybinio socialinio fondo valdybos (Sodrai) bei Valstybinei
                mokesčių inspekcijos puslapius, kuriuose nurodytos sąskaitos
                apskaičiuotų mokesčių sumokėjimui.
            </Typography>
            <br />
            <Typography variant="h5" component="h2">
                Atlyginimo į rankas/ant popieriaus skaičiavimas
            </Typography>
            <br />
            <Typography variant="body1">
                Skaičiuoklėje įvedamas vienas iš trijų laukų. Kitų dviejų laukų
                reikšmės apskaičiuojamos automatiškai.
            </Typography>
            <List>
                <ListItem>
                    <ListItemText>
                        <strong>Atlyginimas ant popieriaus</strong> - darbo sutartyje
                        nurodyta arba priskaičiuota alga neatėmus mokesčių.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <strong>Atlyginimas į rankas</strong> - algos suma, mokėtina
                        darbuotojui atėmus gyventojų pajamų mokestį ir socialinio
                        draudimo įmokas.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <strong>Darbo vietos kaina</strong> - darbdavio išlaidų suma,
                        kurią darbdavys turi išleisti tam, kad galėtų išmokėti
                        atlyginimo į rankas lauke nurodytą sumą.
                    </ListItemText>
                </ListItem>
            </List>
            <br />
            <Typography variant="h5" component="h2">
                Skaičiuoklės nustatymai
            </Typography>
            <br />
            <Typography variant="body1">
                Skaičiuoklėje nurodomi skaičiavimo parametrai, kurių reikšmės
                priklauso nuo darbuotojo ir darbdavio.
            </Typography>
            <List>
                <ListItem>
                    <ListItemText>
                        <strong>Neapmokestinamas pajamų dydis</strong> - taikomas tuo
                        atveju, jeigu darbuotojas darbdaviui pateikia prašymą jį
                        taikyti. Nepateikus prašymo, reikalinga rinktis reikšmę
                        "Netaikomas". Riboto darbingumo atveju atlyginimo skaičiuoklėje
                        nurodomas darbingumo lygis.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <strong>Papildomas pensijos kaupimas</strong> - nurodoma, ar
                        darbuotojas kaupia papildomai pensijos daliai, ar nekaupia, ar
                        kaupia palaipsniui.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <strong>Sutarties tipas</strong> - nurodoma, ar sutartis
                        terminuota, ar neterminuota (be nustatytos galiojimo pabaigos
                        datos).
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <strong>Darbdavio tipas</strong> - uždarųjų akcinių bendrovių,
                        mažųjų bendrijų bei kitų privačių darbdavių atveju nurodomas,
                        kad tai privatus darbdavys.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <strong>Darbdavio grupės</strong> - dauguma darbdavio priklauso
                        I grupei, todėl skaičiuoklėje paprastai bus parenkama ši grupė.
                        Kitoms grupėms darbdavys priskiriamas jeigu yra įvykę nelaimingų
                        atsitikimų.
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <strong>Metai</strong> - parenkami metai, kurių atlyginimas yra
                        skaičiuojamas. Kiekvienais metais mokesčių tarifai ir
                        skaičiavimo tvarka skiriasi.
                    </ListItemText>
                </ListItem>
            </List>
            <Typography variant="body1">
                Atlyginimo skaičiuoklė neatsižvelgia į papildomas socialinio
                draudimo įmokas, kurias reikalinga sumokėti, jeigu darbuotojui
                mokamas atlyginimas yra mažesnis nei minimali mėnesinio alga ir
                darbuotojas nedirba kitoje darbovietėje ar nėra draustas kitu būdu.
            </Typography>
            <br />
            <Typography variant="h5" component="h2">
                NPD (neapmokestinamojo pajamų dydžio) skaičiavimas
            </Typography>
            <br />
            <Typography variant="body1">
                Atlyginimo skaičiuoklė taip pat apskaičiuoja taikomą NPD
                (neapmokestinamąjį pajamų dydį). NPD taikomas ir dalis pajamų
                neapmokestinama tik tuo atveju, jeigu darbuotojas pateikia prašymą
                darbdaviui jį taikyti. Prašymą galima pateikti tik vienoje
                darbovietėje. Skaičiuojant atlyginimą taikoma NPD suma priklauso nuo
                darbuotojo darbingumo lygio.
            </Typography>
            <br />
            <Typography variant="body1">
                Jeigu darbuotojo darbingumas - 0-25 proc., tuomet taikomas
                neapmokestinamasis pajamų dydis yra fiksuota suma, kuri 2021 metais
                lygi 645 EUR per mėnesį. Jeigu darbingumo lygis - 30-55 proc, tuomet
                taikoma NPD suma 2021 metais yra 600 EUR per mėnesį. Kitais atvejais
                taikoma NPD suma apskaičiuojama pagal formulę: NPD = 400 – 0,18 x
                (mėnesio pajamos - 642). Formulėje pajamos yra su darbo santykiais
                susijusios pajamos, t.y. atlyginimas. 642 EUR yra minimalioji
                mėnesinės alga, galiojusi einamųjų kalendorinių metų sausio 1 dieną.
            </Typography>
            <br />
            <div>
                <a
                    href="https://www.linkedin.com/company/atlyginimo-skaiciuokle"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <LinkedInIcon color="primary" fontSize="large" />
                </a>
                <a
                    href="https://www.facebook.com/groups/atlyginimoskaiciuokle"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FacebookIcon color="primary" fontSize="large" />
                </a>
                <a
                    href="https://www.youtube.com/channel/UCjOw3h-skERzDE_X4fwgUYQ"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <YouTubeIcon color="primary" fontSize="large" />
                </a>
            </div>
        </>
    )
}

export default SkaiciuoklesAprasymasComponent
