import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";

const MokesciuAprasymasComponent = () => {
    return (
        <>
            <Typography variant="h5" component="h2">
                Kiek sumokame mokesčių nuo atlyginimo?
            </Typography>
            <br />
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        Mokesčių apskaičiavimo, išskaitymo ir sumokėjimo prievolė tenka
                        darbdaviui. Papildomai darbdavys turi apskaičiuoti ir sumokėti
                        socialinio draudimo mokestį, taikomą darbdaviams.
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        2020 ir 2021 m. skaičiuoklėje naudojami mokesčių tarifai
                        pateikti lentelėje:
                    </Typography>
                    <br />
                    <TableContainer component={Paper} elevation={3}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Gyventojų pajamų mokestis
                                    </TableCell>
                                    <TableCell align="right">20%</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Darbuotojo socialinio draudimo įmokos
                                    </TableCell>
                                    <TableCell align="right">19,5%</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="td" scope="row">
                                        Darbuotojo kaupimas papildomam pensijų draudimui
                                    </TableCell>
                                    <TableCell align="right">3%</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell scope="row" component="td">
                                        Darbdavio socialinio draudimo įmokos
                                    </TableCell>
                                    <TableCell align="right">1,77%</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <Typography variant="body1">
                        Faktinis apmokestinimo procentas yra 40,6% (netaikant NPD ir
                        nekaupiant papildomai pensijai, t.y. iš 100 EUR darbdavio skirto
                        atlyginimui, 40,6 EUR tenka mokesčiams sumokėti, o darbuotojo
                        atlyginimui - 59,4 EUR.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img
                        width="100%"
                        src={`${process.env.PUBLIC_URL}/atlyginimo-skaiciuokle.png`}
                        alt="Atlyginimo skaičiuoklė"
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default MokesciuAprasymasComponent
