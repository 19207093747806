import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import ReactGA from "react-ga";
import {
    changeAntPopieriaus,
    changeDarboVietosKaina, changeDraudejoGrupe,
    changeDraudejoTipas, changeIRankas, changeMetai, changePensijai, changeSutartiesTipas, changeTaikomasNpd, formatAsEmptyIfZero
} from "../skaiciuokle/Skaiciuokle";

const resize = {
    style: {
        fontSize: 34,
        textAlign: "right" as const
    },
};

const SkaiciuokleComponent = ({ duomenys, onDuomenys }: any) => {
    const [aggregateRating, setAggregateRating] = useState({
        ratingValue: 4.9,
        ratingCount: 4325,
        rated: false,
        rating: 0,
    });

    function handleAntPopieriausChange(event: any) {
        onDuomenys({
            ...duomenys,
            ...changeAntPopieriaus(event.target.value, duomenys),
        });

        ReactGA.event({
            category: "Calc",
            action: "gross",
            value: +event.target.value,
        });
    }

    const handleIRankasChange = (event: any) => {
        onDuomenys({
            ...duomenys,
            ...changeIRankas(event.target.value, duomenys),
        });
        ReactGA.event({
            category: "Calc",
            action: "net",
            value: +event.target.value,
        });
    };

    const handleTaikomasNpdChange = (event: any) => {
        onDuomenys({
            ...duomenys,
            ...changeTaikomasNpd(event.target.value, duomenys),
        });
    };

    const handlePensijaiChange = (event: any) => {
        onDuomenys({
            ...duomenys,
            ...changePensijai(event.target.value, duomenys),
        });
    };

    const handleDarboVietosKainaChange = (event: any) => {
        onDuomenys({
            ...duomenys,
            ...changeDarboVietosKaina(event.target.value, duomenys),
        });
        ReactGA.event({
            category: "Calc",
            action: "costs",
            value: +event.target.value,
        });
    };

    const handleMetaiChange = (event: any) => {
        onDuomenys({ ...duomenys, ...changeMetai(event.target.value, duomenys) });
    };

    const handleDraudejoGrupeChange = (event: any) => {
        onDuomenys({
            ...duomenys,
            ...changeDraudejoGrupe(event.target.value, duomenys),
        });
    };

    const handleDraudejoTipasChange = (event: any) => {
        onDuomenys({
            ...duomenys,
            ...changeDraudejoTipas(event.target.value, duomenys),
        });
    };

    const handleSutartiesTipasChange = (event: any) => {
        onDuomenys({
            ...duomenys,
            ...changeSutartiesTipas(event.target.value, duomenys),
        });
    };


    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                        fullWidth
                        inputProps={resize}
                        label="Ant popieriaus:"
                        autoFocus
                        onChange={handleAntPopieriausChange}
                        value={formatAsEmptyIfZero(duomenys.antPopieriaus)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                        fullWidth
                        inputProps={resize}
                        label="Į rankas:"
                        onChange={handleIRankasChange}
                        value={formatAsEmptyIfZero(duomenys.iRankas)}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <TextField
                        fullWidth
                        inputProps={resize}
                        label="Darbo vietos kaina:"
                        onChange={handleDarboVietosKainaChange}
                        value={formatAsEmptyIfZero(duomenys.darboVietosKaina)}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Box sx={{ pt: 5 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            select
                            label="Neapmokestinamas pajamų dydis:"
                            onChange={handleTaikomasNpdChange}
                            value={duomenys.taikomasNpd}
                            variant="outlined"
                        >
                            <MenuItem value="standartinis">Taikomas standartinis</MenuItem>
                            <MenuItem value="netaikomas"> Netaikomas </MenuItem>
                            <MenuItem value="npd0_25">0 - 25 % darbingumas</MenuItem>
                            <MenuItem value="npd30_55">30 - 55 % darbingumas</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            select
                            label="Papildomas pensijos kaupimas:"
                            value={duomenys.pensijai}
                            onChange={handlePensijaiChange}
                            variant="outlined"
                        >
                            <MenuItem value="nekaupia"> Nekaupia </MenuItem>
                            <MenuItem value="kaupiaPalaipsniui">Kaupia palaipsniui</MenuItem>
                            <MenuItem value="kaupia"> Kaupia 3 % </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            select
                            label="Sutarties tipas:"
                            value={duomenys.sutartiesTipas}
                            onChange={handleSutartiesTipasChange}
                            variant="outlined"
                        >
                            <MenuItem value="neterminuota"> Neterminuota </MenuItem>
                            <MenuItem value="terminuota"> Terminuota </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            select
                            label="Darbdavio tipas:"
                            value={duomenys.draudejoTipas}
                            onChange={handleDraudejoTipasChange}
                            variant="outlined"
                        >
                            <MenuItem value="privatus">Privatus</MenuItem>
                            <MenuItem value="biudzetine">Biudžetinė įstaiga</MenuItem>
                            <MenuItem value="lietuvosBankas">Lietuvos bankas</MenuItem>
                            <MenuItem value="politinesPartijos">Politinė partija</MenuItem>
                            <MenuItem value="profesinesSajungos">
                                Profesinė sąjunga
                            </MenuItem>
                            <MenuItem value="religinesBendrijos">
                                Religinėms bendruomenė
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            select
                            label="Darbdavio grupė:"
                            value={duomenys.draudejoGrupe}
                            onChange={handleDraudejoGrupeChange}
                            variant="outlined"
                        >
                            <MenuItem value="I"> I grupė </MenuItem>
                            <MenuItem value="II"> II grupė </MenuItem>
                            <MenuItem value="III"> III grupė </MenuItem>
                            <MenuItem value="IV"> IV grupė </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            select
                            label="Metai:"
                            value={duomenys.metai}
                            onChange={handleMetaiChange}
                            variant="outlined"
                        >
                            <MenuItem value="2022"> 2022 </MenuItem>
                            <MenuItem value="2021"> 2021 </MenuItem>
                            <MenuItem value="2020"> 2020 </MenuItem>
                            <MenuItem value="2019"> 2019 </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </Box>
            <Grid container direction="row" spacing={4}>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                    <br />
                    <Typography variant="body2">
                        <span>
                            Atlyginimo skaičiuoklė suderinta su teisės aktų pakeitimais, įsigaliojusiais nuo{" "}
                        </span>
                        <span>2022 m. sausio 1 d.</span>
                    </Typography>
                    <Typography variant="body2">
                        <span>
                            Skaičiuoklę patikrino <a href="https://www.tezaurus.lt/">audito įmonė UAB "Tezaurus auditas"</a>.
                        </span>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <Box sx={{ mt: 3 }}>
                        <Rating
                            name="rating"
                            value={aggregateRating.rating}
                            onChange={(event, newRating: any) => {
                                setAggregateRating({
                                    ratingValue: aggregateRating.ratingValue,
                                    ratingCount: aggregateRating.rated
                                        ? aggregateRating.ratingCount
                                        : aggregateRating.ratingCount + 1,
                                    rated: true,
                                    rating: newRating,
                                });
                                if (!aggregateRating.rated) {
                                    ReactGA.event({
                                        category: "Rating",
                                        action: "salary_calculator",
                                        value: newRating,
                                    });
                                }
                            }}
                        />
                        <Typography
                            itemProp="aggregateRating"
                            itemScope
                            itemType="https://schema.org/AggregateRating"
                            variant="caption"
                        >
                            <span itemProp="ratingValue">
                                {aggregateRating.ratingValue}
                            </span>{" "}
                            (
                            <span itemProp="ratingCount">
                                {aggregateRating.ratingCount}
                            </span>{" "}
                            įvertinimai)
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default SkaiciuokleComponent
