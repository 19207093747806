import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";

function FooterComponent() {
  return (
    <Grid component={Paper} elevation={3}>
      <Typography
        variant="overline"
        display="block"
        gutterBottom
        color="primary"
        align="center"
      >
        <a href="./kontaktai">Kontaktai</a>
      </Typography>
    </Grid>
  );
}

export default FooterComponent;
