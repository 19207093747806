import { SkaiciuokleParamType } from "./SkaiciuokleParamType";

export const PARAM: { [index: string]: SkaiciuokleParamType } = {
  "2022": {
    gpm: {
      tarifas: 20,
      npdDuomenys: [{
        tipas: "standartinis",
        taikomaIki: 1678,
        fixNpd: 460,
        mma: 730,
        maxPajamos: 2499.230769,
        npdBeta: 0.26,
      },
      {
        tipas: "standartinis",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 400,
        mma: 642,
        maxPajamos: 2864.222222,
        npdBeta: 0.18,
      },
      {
        tipas: "npd0_25",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 740
      },
      {
        tipas: "npd30_55",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 690
      }]
    },
    darbuotojoTarifas: {
      nekaupia: 19.5,
      kaupiaPalaipsniui: 22.2,
      kaupia: 22.5,
    },
    sutartiesTipas: {
      neterminuota: 1.31,
      terminuota: 2.03,
    },
    draudejoTipas: {
      privatus: 0.32,
      biudzetine: 0,
      lietuvosBankas: 0,
      politinesPartijos: 0.16,
      profesinesSajungos: 0.16,
      religinesBendrijos: 0.16,
    },
    draudejoGrupe: {
      I: 0.14,
      II: 0.4,
      III: 0.7,
      IV: 1.4,
    },
  },
  "2021": {
    gpm: {
      tarifas: 20,
      npdDuomenys: [{
        tipas: "standartinis",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 400,
        mma: 642,
        maxPajamos: 2864.222222,
        npdBeta: 0.18,
      },
      {
        tipas: "npd0_25",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 645
      },
      {
        tipas: "npd30_55",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 600
      }]
    },
    darbuotojoTarifas: {
      nekaupia: 19.5,
      kaupiaPalaipsniui: 21.9,
      kaupia: 22.5,
    },
    sutartiesTipas: {
      neterminuota: 1.31,
      terminuota: 2.03,
    },
    draudejoTipas: {
      privatus: 0.32,
      biudzetine: 0,
      lietuvosBankas: 0,
      politinesPartijos: 0.16,
      profesinesSajungos: 0.16,
      religinesBendrijos: 0.16,
    },
    draudejoGrupe: {
      I: 0.14,
      II: 0.4,
      III: 0.7,
      IV: 1.4,
    },
  },
  "2020": {
    gpm: {
      tarifas: 20,
      npdDuomenys: [{
        tipas: "standartinis",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 400,
        mma: 607,
        maxPajamos: 2712.263158,
        npdBeta: 0.19,
      },
      {
        tipas: "npd0_25",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 645
      },
      {
        tipas: "npd30_55",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 600
      }]
    },
    darbuotojoTarifas: {
      nekaupia: 19.5,
      kaupiaPalaipsniui: 21.6,
      kaupia: 22.5,
    },
    sutartiesTipas: {
      neterminuota: 1.31,
      terminuota: 2.03,
    },
    draudejoTipas: {
      privatus: 0.32,
      biudzetine: 0,
      lietuvosBankas: 0,
      politinesPartijos: 0.16,
      profesinesSajungos: 0.16,
      religinesBendrijos: 0.16,
    },
    draudejoGrupe: {
      I: 0.14,
      II: 0.4,
      III: 0.7,
      IV: 1.4,
    },
  },
  "2019": {
    gpm: {
      tarifas: 20,
      npdDuomenys: [{
        tipas: "standartinis",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 300,
        mma: 555,
        maxPajamos: 2555,
        npdBeta: 0.15,
      },
      {
        tipas: "npd0_25",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 353
      },
      {
        tipas: "npd30_55",
        taikomaIki: Number.POSITIVE_INFINITY,
        fixNpd: 308
      }]
    },
    darbuotojoTarifas: {
      nekaupia: 19.5,
      kaupiaPalaipsniui: 21.3,
      kaupia: 22.5,
    },
    sutartiesTipas: {
      neterminuota: 1.31,
      terminuota: 2.03,
    },
    draudejoTipas: {
      privatus: 0.32,
      biudzetine: 0,
      lietuvosBankas: 0,
      politinesPartijos: 0.16,
      profesinesSajungos: 0.16,
      religinesBendrijos: 0.16,
    },
    draudejoGrupe: {
      I: 0.14,
      II: 0.36,
      III: 0.7,
      IV: 1.4,
    },
  },
};
