import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from "react";
import { format } from "../skaiciuokle/Skaiciuokle";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold
}));

const RezultatuLenteleComponent = ({ duomenys }: any) => {
    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Atlyginimas neatskaičius mokesčių (ant popieriaus):
                        </TableCell>
                        <TableCell align="right">
                            {format(duomenys.antPopieriaus)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Gyventojų pajamų mokestis (tarifas {duomenys.gpmTarifas}
                            %, pritaikytas NPD {format(duomenys.apskaiciuotasNpd)}):
                        </TableCell>
                        <TableCell align="right">{format(duomenys.gpm)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Darbuotojo soc.draudimo įmoka (tarifas{" "}
                            {duomenys.darbuotojoTarifas}%):
                        </TableCell>
                        <TableCell align="right">
                            {format(duomenys.darbuotojoImoka)}
                        </TableCell>
                    </TableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            Mokėtinas atlyginimas atskaičius mokesčius (į rankas):
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            {format(duomenys.iRankas)}
                        </StyledTableCell>
                    </StyledTableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Darbdavio soc.draudimo įmoka (tarifas{" "}
                            {duomenys.darbdavioTarifas}%):
                        </TableCell>
                        <TableCell align="right">
                            {format(duomenys.darbdavioImoka)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Darbo vietos kaina (darbdavio išlaidos):
                        </TableCell>
                        <TableCell align="right">
                            {format(duomenys.darboVietosKaina)}
                        </TableCell>
                    </TableRow>
                    <StyledTableRow>
                        <StyledTableCell component="td" scope="row">
                            Sodrai mokėtina suma, įmokos kodas 252 (
                            <a
                                href="https://www.sodra.lt/surenkamosios-saskaitos"
                                target="_blank"
                                rel="noopener noreferrer nofollow "
                            >
                                Sodros sąskaitos
                            </a>
                            ):
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            {format(duomenys.darbuotojoImoka + duomenys.darbdavioImoka)}
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell scope="row" component="td">
                            Mokesčių inspekcijai mokėtina suma, įmokos kodas 1311(
                            <a
                                href="https://www.vmi.lt/cms/biudzeto-pajamu-surenkamoji-saskaita"
                                target="_blank"
                                rel="noopener noreferrer nofollow "
                            >
                                VMI sąskaitos
                            </a>
                            ):
                        </StyledTableCell>
                        <StyledTableCell align="right">
                            {format(duomenys.gpm)}
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default RezultatuLenteleComponent
