import ChangedFields from "../ChangedFields";
import { PARAM } from "./SkaiciuokleParam";
import { DarbuotojoTarifasType, DraudejoGrupeType, DraudejoTipasType, NPDDuomenysType, SutartiesTipasType } from "./SkaiciuokleParamType";

export const format = (number: any) => {
  let num = +number;

  if (isNaN(number)) num = 0;

  return num.toFixed(2);
};

export const formatAsEmptyIfZero = (number: any) => {
  if (number === 0) return "";

  return number;
};

export const emptyState = () => {
  return {
    iRankas: 0,
    antPopieriaus: 0,
    darboVietosKaina: 0,
    apskaiciuotasNpd: 0,
    darbuotojoImoka: 0,
    gpm: 0,
    darbdavioImoka: 0,
  };
};

export const changeIRankas = (newValue: any, state: any) => {
  if (isNaN(newValue)) {
    newValue = newValue.replace(",", ".");
  }

  if (!newValue || isNaN(newValue)) {
    return { ...emptyState(), iRankas: newValue };
  }

  let iRankas = +newValue;
  let antPopieriaus = calculateAntPopieriaus(
    state.metai,
    iRankas,
    state.taikomasNpd,
    state.pensijai
  );
  let apskaiciuotasNpd = calculateApskaiciuotasNpd(
    state.metai,
    antPopieriaus,
    state.taikomasNpd
  );
  let gpm = calculateGpm(antPopieriaus, apskaiciuotasNpd);
  let darbuotojoTarifas = calculateDarbuotojoTarifas(
    state.metai,
    state.pensijai
  );
  let darbuotojoImoka = calculateDarbuotojoImoka(
    antPopieriaus,
    darbuotojoTarifas
  );
  let darbdavioTarifas = calculateDarbdavioTarifas(
    state.metai,
    state.sutartiesTipas,
    state.draudejoTipas,
    state.draudejoGrupe
  );
  let darbdavioImoka = calculateDarbdavioImoka(antPopieriaus, darbdavioTarifas);
  let darboVietosKaina = calculateDarboVietosKaina(
    antPopieriaus,
    darbdavioImoka
  );

  return {
    changedField: ChangedFields.NET,
    antPopieriaus: antPopieriaus,
    iRankas: newValue,
    apskaiciuotasNpd: apskaiciuotasNpd,
    darbuotojoImoka: darbuotojoImoka,
    darbuotojoTarifas: darbuotojoTarifas,
    darbdavioImoka: darbdavioImoka,
    darbdavioTarifas: darbdavioTarifas,
    darboVietosKaina: darboVietosKaina,
    gpm: gpm,
    pensijai: state.pensijai,
    sutartiesTipas: state.sutartiesTipas,
    taikomasNpd: state.taikomasNpd,
    metai: state.metai,
    draudejoTipas: state.draudejoTipas,
    draudejoGrupe: state.draudejoGrupe,
  };
};

export const changeAntPopieriaus = (newValue: any, state: any) => {
  if (isNaN(newValue)) {
    newValue = newValue.replace(",", ".");
  }

  if (!newValue || isNaN(newValue)) {
    return { ...emptyState(), antPopieriaus: newValue };
  }

  let antPopieriaus = +newValue;
  let apskaiciuotasNpd = calculateApskaiciuotasNpd(
    state.metai,
    antPopieriaus,
    state.taikomasNpd
  );
  let gpm = calculateGpm(antPopieriaus, apskaiciuotasNpd);
  let darbuotojoTarifas = calculateDarbuotojoTarifas(
    state.metai,
    state.pensijai
  );
  let darbuotojoImoka = calculateDarbuotojoImoka(
    antPopieriaus,
    darbuotojoTarifas
  );
  let iRankas = calculateIRankas(antPopieriaus, gpm, darbuotojoImoka);
  let darbdavioTarifas = calculateDarbdavioTarifas(
    state.metai,
    state.sutartiesTipas,
    state.draudejoTipas,
    state.draudejoGrupe
  );
  let darbdavioImoka = calculateDarbdavioImoka(antPopieriaus, darbdavioTarifas);
  let darboVietosKaina = calculateDarboVietosKaina(
    antPopieriaus,
    darbdavioImoka
  );

  return {
    changedField: ChangedFields.GROSS,
    antPopieriaus: newValue,
    iRankas: iRankas,
    apskaiciuotasNpd: apskaiciuotasNpd,
    darbuotojoImoka: darbuotojoImoka,
    darbuotojoTarifas: darbuotojoTarifas,
    darbdavioImoka: darbdavioImoka,
    darbdavioTarifas: darbdavioTarifas,
    darboVietosKaina: darboVietosKaina,
    gpm: gpm,
    pensijai: state.pensijai,
    sutartiesTipas: state.sutartiesTipas,
    taikomasNpd: state.taikomasNpd,
    metai: state.metai,
    draudejoTipas: state.draudejoTipas,
    draudejoGrupe: state.draudejoGrupe,
  };
};

export const changeDarboVietosKaina = (newValue: any, state: any) => {
  if (isNaN(newValue)) {
    newValue = newValue.replace(",", ".");
  }

  if (!newValue || isNaN(newValue)) {
    return { ...emptyState(), darboVietosKaina: newValue };
  }

  let darboVietosKaina = +newValue;
  let darbdavioTarifas = calculateDarbdavioTarifas(
    state.metai,
    state.sutartiesTipas,
    state.draudejoTipas,
    state.draudejoGrupe
  );
  let antPopieriaus = calculateAntPopieriausIsDarboVietosKainos(
    darboVietosKaina,
    darbdavioTarifas
  );
  let apskaiciuotasNpd = calculateApskaiciuotasNpd(
    state.metai,
    antPopieriaus,
    state.taikomasNpd
  );
  let gpm = calculateGpm(antPopieriaus, apskaiciuotasNpd);
  let darbuotojoTarifas = calculateDarbuotojoTarifas(
    state.metai,
    state.pensijai
  );
  let darbuotojoImoka = calculateDarbuotojoImoka(
    antPopieriaus,
    darbuotojoTarifas
  );
  let iRankas = calculateIRankas(antPopieriaus, gpm, darbuotojoImoka);
  let darbdavioImoka = calculateDarbdavioImoka(antPopieriaus, darbdavioTarifas);

  return {
    changedField: ChangedFields.EXPENSES,
    antPopieriaus: antPopieriaus,
    iRankas: iRankas,
    apskaiciuotasNpd: apskaiciuotasNpd,
    darbuotojoImoka: darbuotojoImoka,
    darbuotojoTarifas: darbuotojoTarifas,
    darbdavioImoka: darbdavioImoka,
    darbdavioTarifas: darbdavioTarifas,
    darboVietosKaina: newValue,
    gpm: gpm,
    pensijai: state.pensijai,
    sutartiesTipas: state.sutartiesTipas,
    taikomasNpd: state.taikomasNpd,
    metai: state.metai,
    draudejoTipas: state.draudejoTipas,
    draudejoGrupe: state.draudejoGrupe,
  };
};

const changedInput = (state: any) => {
  if (state.changedField === ChangedFields.GROSS)
    return changeAntPopieriaus(state.antPopieriaus, state);

  if (state.changedField === ChangedFields.NET)
    return changeIRankas(state.iRankas, state);

  if (state.changedField === ChangedFields.EXPENSES)
    return changeDarboVietosKaina(state.darboVietosKaina, state);

  return changeAntPopieriaus(state.antPopieriaus, state);
};

export const changeSutartiesTipas = (sutartiesTipas: string, state: any) => {
  state.sutartiesTipas = sutartiesTipas;
  return changedInput(state);
};

export const changePensijai = (pensijai: string, state: any) => {
  state.pensijai = pensijai;
  return changedInput(state);
};

export const changeTaikomasNpd = (taikomasNpd: string, state: any) => {
  state.taikomasNpd = taikomasNpd;
  return changedInput(state);
};

export const changeDraudejoTipas = (draudejoTipas: string, state: any) => {
  state.draudejoTipas = draudejoTipas;
  return changedInput(state);
};

export const changeDraudejoGrupe = (draudejoGrupe: string, state: any) => {
  state.draudejoGrupe = draudejoGrupe;
  return changedInput(state);
};

export const changeMetai = (metai: string, state: any) => {
  state.metai = metai;
  return changedInput(state);
};

export const calculateAntPopieriausIsDarboVietosKainos = (
  darboVietosKaina: number,
  darbdavioTarifas: number
) => {
  return round((darboVietosKaina / (100 + darbdavioTarifas)) * 100);
};

export const calculateDarboVietosKaina = (
  antPopieriaus: number,
  darbdavioImoka: number
) => {
  let rounded = round(antPopieriaus + darbdavioImoka);
  return rounded;
};

export const calculateAntPopieriaus = (
  metai: string,
  iRankas: number,
  taikomasNpd: string,
  pensijai: string
) => {
  const galimiNpdDuomenys = PARAM[metai].gpm.npdDuomenys.filter(o => o.tipas === taikomasNpd);

  /** Kai galimos kelios formulės, tai pirma apskaičiuojama suma ant popieriaus, tada tikrinama, 
   * ar galėjo būti taikoma
   */
  for (let i = 0; i < galimiNpdDuomenys.length; i++) {
    const npdDuomenys = galimiNpdDuomenys[i]
    const antPopieriaus = calculateAntPopieriausTaikantNpd(metai, iRankas, pensijai, npdDuomenys)
    if (antPopieriaus <= npdDuomenys.taikomaIki)
      return antPopieriaus
  }

  return 0
};

export const calculateAntPopieriausTaikantNpd = (metai: string,
  iRankas: number,
  pensijai: string,
  npdDuomenys?: NPDDuomenysType
) => {
  let socDrTarifas = calculateDarbuotojoTarifas(metai, pensijai);
  let fixNpd = npdDuomenys?.fixNpd ?? 0;
  let maxPajamosLygiosNpd = (fixNpd * (100 - socDrTarifas)) / 100;
  if (iRankas <= maxPajamosLygiosNpd)
    return calculateAntPopieriausNpdLygusPajamoms(metai, iRankas, pensijai);

  let mma = npdDuomenys?.mma ?? 0;
  let gpmTarifas = PARAM[metai].gpm.tarifas;
  let maxPajamosFiksuotasNpd =
    mma === 0
      ? Number.MAX_VALUE
      : mma * (1 - socDrTarifas / 100) - ((mma - fixNpd) * gpmTarifas) / 100;
  if (iRankas <= maxPajamosFiksuotasNpd)
    return calculateAntPopieriausNpdPastovus(metai, iRankas, pensijai, fixNpd);

  let maxPajamos = npdDuomenys?.maxPajamos || 0;
  let maxPajamosMazejantisNpd =
    maxPajamos === 0
      ? Number.MAX_VALUE
      : maxPajamos * (1 - socDrTarifas / 100 - gpmTarifas / 100);
  if (iRankas <= maxPajamosMazejantisNpd)
    return calculateAntPopieriausNpdMazejantis(metai, iRankas, pensijai, npdDuomenys);

  return calculateAntPopieriausNetaikantNpd(metai, iRankas, pensijai);
}

export const calculateAntPopieriausNpdLygusPajamoms = (
  metai: string,
  iRankas: number,
  pensijai: string
) => {
  let darbuotojoTarifas = calculateDarbuotojoTarifas(metai, pensijai);
  let antPopieriaus = round((iRankas / (100 - darbuotojoTarifas)) * 100);
  return antPopieriaus;
};

export const calculateAntPopieriausNpdPastovus = (
  metai: string,
  iRankas: number,
  pensijai: string,
  npd: number
) => {
  let darbuotojoTarifas = calculateDarbuotojoTarifas(metai, pensijai);
  let gpmTarifas = PARAM[metai].gpm.tarifas;
  let antPopieriaus = round(
    ((iRankas - (npd * gpmTarifas) / 100) /
      (100 - gpmTarifas - darbuotojoTarifas)) *
    100
  );
  return antPopieriaus;
};

export const calculateAntPopieriausNpdMazejantis = (
  metai: string,
  iRankas: number,
  pensijai: string,
  npdDuomenys?: NPDDuomenysType
) => {
  let darbuotojoTarifas = calculateDarbuotojoTarifas(metai, pensijai);
  let mma = npdDuomenys?.mma ?? 0;
  let npdParam1 = npdDuomenys?.fixNpd ?? 0;
  let npdParam2 = npdDuomenys?.npdBeta ?? 0;
  let gpmTarifas = PARAM[metai].gpm.tarifas;
  let antPopieriaus = round(
    ((iRankas -
      (npdParam1 * gpmTarifas) / 100 -
      (npdParam2 * mma * gpmTarifas) / 100) /
      (100 - gpmTarifas - npdParam2 * gpmTarifas - darbuotojoTarifas)) *
    100
  );
  return antPopieriaus;
};

export const calculateAntPopieriausNetaikantNpd = (
  metai: string,
  iRankas: number,
  pensijai: string
) => {
  let darbuotojoTarifas = calculateDarbuotojoTarifas(metai, pensijai);
  let gpmTarifas = PARAM[metai].gpm.tarifas;
  let antPopieriaus = round(
    (iRankas / (100 - gpmTarifas - darbuotojoTarifas)) * 100
  );
  return antPopieriaus;
};

export const calculateGpmTarifas = (metai: string) => {
  return PARAM[metai].gpm.tarifas;
};

export const calculateDarbuotojoTarifas = (metai: string, pensijai: string) => {
  return PARAM[metai].darbuotojoTarifas[pensijai as keyof DarbuotojoTarifasType];
};

export const calculateDarbdavioTarifas = (
  metai: string,
  sutartiesTipas: string,
  draudejoTipas: string,
  draudejoGrupe: string
) => {
  let nedarbo = PARAM[metai].sutartiesTipas[sutartiesTipas as keyof SutartiesTipasType];
  let garantiniamFonduiIrIlgNed = PARAM[metai].draudejoTipas[draudejoTipas as keyof DraudejoTipasType];
  let nelaiminguAtsitikimu = PARAM[metai].draudejoGrupe[draudejoGrupe as keyof DraudejoGrupeType];

  return round(nedarbo + garantiniamFonduiIrIlgNed + nelaiminguAtsitikimu);
};

export const calculateDarbdavioImoka = (
  antPopieriaus: number,
  tarifas: number
) => {
  return round((antPopieriaus * tarifas) / 100);
};

export const round = (number: number) => {
  let rounded = Math.round((number + Number.EPSILON) * 100) / 100;
  return rounded;
};

export const calculateIRankas = (
  antPopieriaus: number,
  gpm: number,
  darbuotojoImoka: number
) => {
  return round(antPopieriaus - gpm - darbuotojoImoka);
};

export const calculateDarbuotojoImoka = (
  antPopieriaus: number,
  tarifas: number
) => {
  let imoka = round((antPopieriaus * tarifas) / 100);
  return imoka;
};

export const calculateGpm = (
  antPopieriaus: number,
  apskaiciuotasNpd: number
) => {
  let imoka = round(((antPopieriaus - apskaiciuotasNpd) * 20) / 100);
  return imoka;
};

export const calculateApskaiciuotasNpd = (
  metai: string,
  antPopieriaus: number,
  taikomasNpd: string
) => {
  if (antPopieriaus < 0) return 0;

  const npdDuomenys = PARAM[metai].gpm.npdDuomenys.find(o => o.tipas === taikomasNpd && o.taikomaIki >= antPopieriaus);
  let maxPajamosLygiosNpd = npdDuomenys?.fixNpd || 0;
  if (antPopieriaus <= maxPajamosLygiosNpd) return antPopieriaus;

  let maxPajamosFiksuotasNpd = npdDuomenys?.mma || Number.MAX_VALUE;
  if (antPopieriaus <= maxPajamosFiksuotasNpd) return maxPajamosLygiosNpd;

  let maxPajamosTaikomasNpd = npdDuomenys?.maxPajamos || 0;

  if (antPopieriaus <= maxPajamosTaikomasNpd) {
    let mma = npdDuomenys?.mma ?? 0;
    let npdBeta = npdDuomenys?.npdBeta ?? 0;
    return round(maxPajamosLygiosNpd - npdBeta * (antPopieriaus - mma));
  }

  return 0;
};
